
.loading-indicator {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    opacity: 0.8;
    border-radius: 10px;
    z-index: 50;

    &.full-screen {
      position: fixed;
    }
  }