.splash {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 0 !important;
    @media (max-width: 768px) {
        .navbar {
            padding: 10px 0px !important;
            .logo-brand {
                img.logo-img {
                    height: 40px !important;
                }
                img.logo-brand-img {
                    height: 40px !important;
                }
            }
        }
    }
    .navbar {
        padding: 15px 0px;
        background: #024089;
        .logo-brand {
            img.logo-img {
                height: 50px;
                width: auto;
            }
            img.logo-brand-img {
                margin-left: 10px;
                height: 50px;
                width: auto;
            }
        }
        .mobile-top-menu {
            .menu-items {
                @media (max-width: 400px) {
                    display: none !important;
                }
            }
            .advisor-online-status {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                position: absolute;
                right: -2px;
                top: 2px;
    
                &.online {
                    background-color: green;
                    border: 2px solid #fff;
                }
                &.offline {
                    background-color: #ddd;
                    border: 2px solid #aaa;
                }                
            }
            .toggle-drawmenu {
                padding: 4px 10px !important;
                background: #e7832b;
            }
            .mobile-authuser-menu {
                position: relative;
                &::after {
                    display: inline-block;
                    margin-left: 0.255em;
                    vertical-align: 0.255em;
                    content: '';
                    color: #eee;
                    border-top: .3em solid;
                    border-right: .3em solid transparent;
                    border-bottom: 0;
                    border-left: .3em solid transparent;
                }
            }
        }
        .top-navbar {
            ul.main-menu {
                list-style: none;
                font-size: 120%;
                display: flex;
                margin-right: 30px !important;
                li {
                    color: #Cbf0ff;
                    .icon {
                        margin-right: 5px;
                    }
                    &.active {
                        font-weight: bold;
                        color: yellow;
                    }
                    &:hover {
                        color: #77f648;
                        text-decoration: underline;
                        .icon {
                            fill: #77f648;
                        }
                    }
                    &:not(:last-child) {
                        &::after {
                            content: '|';
                            padding: 0 15px;
                        }
                    }
                }
            }
            .auth-action {
                button {
                    width: 110px;
                }
            }
            .auth-welcome {
                .welcome {
                    color: yellow;
                    img {
                        height: 25px;
                    }
                }
                .username {
                    color: rgb(111, 247, 111);
                }
            }
            .authuser-action {
                position: relative;
                .advisor-online-status {
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    position: absolute;
                    right: 15px;
                    top: 5px;
        
                    &.online {
                        background-color: green;
                        border: 2px solid #fff;
                    }
                    &.offline {
                        background-color: #ddd;
                        border: 2px solid #bbb;
                    }
                }
            }
            .authuser-menu {
                li {
                    cursor: pointer;    
                    padding: 10px;
                }
            }
        }
        .drawer-navbar {
            position: fixed;
            left: -280px;
            width: 280px;
            top: 0;
            bottom: 0;
            transition: all 0.3s;
            background: #eef5fa;
            z-index: 10000;
            &.open{
                transform: translateX(280px);
            }
    
            ul {
                list-style-type: none;
                padding: 10px 10px;
                li {
                    padding: 8px 5px;
                    border-radius: 5px;
                    &.active {
                        color: #06683e;
                        background: rgb(220, 240, 231);
                        font-weight: bold;
                    }
                    a {
                        color: #333;
                        &:hover {
                            color: green;
                        }
                    }
                    &.splitter-bar {
                        position: relative;
                        padding:0px;
                        margin:5px 0 10px 0;
                        &::after {
                            content: '';
                            position: absolute;
                            left: 0px;
                            right: 0px;
                            bottom: 0px;
                            height: 1px;
                            background-color: #ccc;
                        }
                    }
                }

            }
            .contact-info {
                position: absolute;
                bottom: 6px;
                left: 12px;
                font-size: 100%;
            }
        }
        .alarm-notification {
            position: relative;
            cursor: pointer;
            .alarm-count {
                position: absolute;
                border-radius: 50%;
                color: white;
                background-color: purple;
                top: 0px;
                top: -6px;
                right: -6px;
                width: 20px;
                height: 20px;
                font-size: 75%;
            }
        }
        .chatoff-threads-popup {
            position: absolute;
            overflow-y: auto;
            top: 70px;
            right: 10px;
            width: 400px;
            max-height: 600px;
            z-index: 10000;
            background-color: white;
            border-radius: 10px;
            box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
            padding: 5px;
        }
        .alarms {
            position: absolute;
            top: 70px;
            right: 10px;
            width: 350px;
            z-index: 10000;
            background-color: white;
            border-radius: 10px;
            box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
            padding: 5px;
            @media (max-width: 772px) {
                top: 50px;
            }
    
            .one-alarm {
                cursor: pointer;
                padding: 10px 10px;
                &:hover {
                    color: rgb(2, 88, 141);
                    .content {
                        text-decoration: underline;
                    }
                }
                .time {
                    padding-right: 10px;
                    font-size: 80%;
                    color: #aaa;
                }
            }
        }
    }
}

.main-body-section {
    min-height: calc(100vh - 150px);
    margin-top: 85px;
    // margin-bottom: 60px;
    overflow: hidden;
    @media (max-width: 768px) {
        margin-top: 60px !important;
        // margin-bottom: 10px;
    }
}

.footer {
    // position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #EAF5FF;
    color: #111;
    z-index: 100;
    .client-footer {
        a {
            font-family: "Roboto, sans-serif";
            font-size: 110%;
            color: #333;
            cursor: pointer;
            font-style: normal;
            transition: all 0.3s;
            &:hover {
                padding-left: 3px !important;
            }
        }
    }
    a {
        font-family: League Gothic;
        font-size: '28px';
        color: #516292;
        cursor: pointer;
        font-style: italic;
        transition: all 0.3s;
        &:hover {
            padding-left: 3px !important;
        }
    }
    .become-advisor {
        left: 0px;
        a {
            &:hover {
                color: green !important;
            }
        }
        p {
            color: #555;
            font-size: 1.25rem;
        }
        .hand-point-left {
            position: relative;
            animation-name: move-horizon-animation;
            animation-duration: 0.8s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-direction: alternate;
        }
    }
    .footer-item{
        a {
            font-family: "Roboto, sans-serif";
            font-size: 20px;
            color: #516292;
            cursor: pointer;
            font-style: italic;
            transition: all 0.3s;
            &:hover {
                padding-left: 3px !important;
            }
        }
    }
    .social-link {
        width: 35px;
        height: 35px;
        text-align: center;
        border: 3px solid #5c93ec;
        background: linear-gradient(90deg, rgb(242 249 255), rgb(229 237 250));
        border-radius: 10px;
        &.facebook {
            i {
                color: #0c44ab;
            }
        }
        &.youtube {
            i {
                color: red;
            }
        }
        &.instagram {
            i {
                color: #a70000;
            }
        }
        &.telegram-plane {
            i {
                color: #437be3;
            }
        }
        &.discord {
            i {
                color: #012088;
            }
        }
        &.whatsapp {
            i {
                color: #008200;
            }
        }
    }
    .footer-expand {
        position: absolute;
        right: 0px;
        bottom: 10px;
        // @media (max-width: 798px) {
        //     right: 20px !important;
        // }
        // @media (max-width: 580px) {
        //     right: 20px !important;
        // }
        // @media (max-width: 473px) {
        //     bottom: 10px !important;
        // }
    }
    .footer-link {
        padding-right: 20px;
        text-align: center;
        font-size: 28px;
        font-family: League Gothic;
        color: #516292;
    }
    .contact-link {
        font-family: sans-serif;
        font-size: 20px;
        text-transform: none;

    }
}

.top-left-toolbar {
    position: absolute;
    top: 65px;
    left: 5px;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
    color:'#3a94cb';

    @media (max-width:1024px) {
        left: 0px !important;

    }
    @media (max-width: 772px) {
        top: 50px !important;
    }

    // animation-name: move-horizon-animation;
    // animation-duration: 0.8s;
    // animation-iteration-count: infinite;
    // animation-timing-function: linear;
    // animation-direction: alternate;
}
.goto-top-section {
    position: fixed;
    right: 50px;
    bottom: 50px;
    cursor: pointer;
    z-index: 100;
    animation-name: move-vertical-animation;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
    @media (max-width: 768px) {
        right: 20px;
    }
    div {
        width:40px;
        height: 40px;
        border-radius: 50%;
        border: 3px solid #367e34;
    }
}
.goto-bottom-section {
    display: none;
    position: fixed;
    right: 50px;
    top: 90px;
    cursor: pointer;
    z-index: 100;
    animation-name: move-vertical-animation;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
    @media (max-width: 768px) {
        right: 20px;
        top: 65px;
    }
    div {
        width:40px;
        height: 40px;
        border-radius: 50%;
        border: 3px solid #367e34;
    } 
}
@keyframes move-vertical-animation {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(5px);
    }
}
@keyframes move-horizon-animation  {
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(5px);
    }
}