.advisor-payment {
    .advisor-earning-card {
        .my-earning {
            @media (max-width: 768px) {
                flex-direction: column;
            }
            .value {
                font-size: 140% !important;
            }
            .total-earning .value {
                color: green;
            }
            .total-withdraw .value {
                color: purple;
            }
            .available-balance .value {
                font-size: 150%;
                color: green;
            }
        }
    }
    
    .advisor-payment-methods-card {
        .payment-method {
            border-radius: 5px;
            box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
            padding: 10px;
            .stripe {
                padding: 5px;
                border-radius: 5px;
                background: rgb(15, 171, 15);
                color: white;
            }
        }
    }
    
    .transactions-card {
        table {
            min-width: 350px;
            tbody {
                tr {
                    .payment-status {
                        padding: 1px;
                        border-radius: 5px;
                        text-align: center;
                        width: 140px;
                        &.payout_success {
                            background: #24b324;
                            color: white;
                        }
                        &.payout_unclaimed {
                            background: pink;
                            color: #555;
                        }
                    }
                }
            }
        }
    }
    
    .pending-chats-card {
        table {
            min-width: 600px;
        }   
    }
}
