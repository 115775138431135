.admin-table-container {
    overflow-x: auto;
    .admin-table {
        font-size: 14px !important;
        thead {
            background-color: #fafafa;
            tr {
                th {
                    padding: 15px 10px;
                    font-size: 100%;
                    font-weight: 500;
                }
                th:first-child {
                    border-top-left-radius: 10px;
                }
                th:last-child {
                    border-top-right-radius: 10px;
                }
            }
        }
        tbody {
            tr {
                &:hover {
                    background-color: #f5f5f5;
                }
                border-bottom: 1px solid #efefef;
                td {
                    padding: 15px 10px;
                    font-weight: 400;
                }
            }
        }
    }
}