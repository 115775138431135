.admin-edit-opinion {
    border-radius: 10px;
    label {
        width: 80px;
    }
}
.admin-opinion-table {
    .opinion-content {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
}