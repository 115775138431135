.scroll-horizon-container {
    position: relative;
    overflow: auto;

    .scroll-left-btn {
        position: absolute;
        top: calc(50%);
    }
    .scroll-right-btn {
        position: absolute;
        top: calc(50%);
    }
}
