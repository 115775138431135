.statistic-card {
    margin-bottom: 20px;
    height: 120px;
    .card-header {
        border-width: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .card-body {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        .statistic-item {
            &:hover{
                text-decoration: underline;
            }
            .label {
                min-width:80px;
            }
            .value {
                font-size: 120%;
            }
        }
    }
}

.today-performance {
    background: rgb(255,242,213);
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    .ctp-amount {
        background: #ecffff;
        border-radius: 5px;
        padding: 0px 50px;
        line-height: 1.25em;
        color: #139913;
        font-size: 40px;
    }
}