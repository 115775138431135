.privacy-policy {
    .title {
        color: #04549b;
        font-weight: bold;
    }
    .sub-title {
        font-weight: bold;
    }
    .sub-title-2 {
        color: #04549b;
        font-weight: bold;
    }
    .policy-item {
        display: flex;
        padding: 0px 10px 10px 20px;

        &::before {
            padding-right: 10px;
            content: '-';
        }
    }
}