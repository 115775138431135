
section .flicking-viewport{
    padding: 20px 0;
}
.introduction-container {
    // background-color: rgb(242,238,244);
    padding: 4px;
    @media (max-width: 768px) {
        background-color: white !important;
        padding: 0;
    }
    font-size: 110%;
    .confide-intro-head {
        text-align: center;
        padding: 2px;
        .intro-header-blur-left {
            position: absolute;
            width: 80px;
            top: -180%;
            left: 25%;
            opacity: 40%;
        }
        .intro-header-blur-right {
            position: absolute;
            width: 80px;
            top: -120%;
            right: 25%;
            opacity: 40%;
        }


        @media (max-width:1400px) {
            .intro-header-blur-left {
                width: 70px;
                top: -140%;
                left: 22%;
            }
            .intro-header-blur-right {
                width: 90px;
                top: -120%;
                right: 20%;
            }
        }
        @media (max-width:1200px) {
            .intro-header-blur-left {
                position: absolute;
                width: 70px;
                top: -160%;
                left: 18%;
            }
            .intro-header-blur-right {
                position: absolute;
                width: 90px;
                top: -120%;
                right: 14%;
            }
        }

        @media (max-width:990px) {
            .intro-header-blur-left {
                position: absolute;
                width: 60px;
                top: -150%;
                left: calc(50% - 290px);
            }
            .intro-header-blur-right {
                position: absolute;
                width: 60px;
                top: -90%;
                right: calc(50% - 280px);
            }
        }
        @media (max-width:800px) {
            margin-bottom: 0px !important;
            padding: 0;
            h1 {
                font-size: 32px !important;
            }
            p {
                font-size: 26px !important;
            }
        }
        @media (max-width:670px) {
            margin-bottom: 0px !important;
            .intro-header-blur-left {
                position: absolute;
                width: 60px;
                top: -150%;
                left: calc(50% - 220px);
            }
            .intro-header-blur-right {
                position: absolute;
                width: 70px;
                top: -100%;
                right: calc(50% - 200px);
            }
            // h1 {
            //     font-size: 120% !important;
            // }
            p {
                font-size: 20px !important;
            }
        }
        // @media (max-width:576px) {
        //     margin-bottom: 0px !important;
        //     h1 {
        //         font-size: 120% !important;
        //     }
        //     p {
        //         font-size: 110% !important;
        //     }
        // }
        @media (max-width:474px) {
            margin-bottom: 12px;
            .intro-header-blur-left {
                position: absolute;
                width: 60px;
                top: -170%;
                left: calc(50% - 180px);
            }
            .intro-header-blur-right {
                position: absolute;
                width: 60px;
                top: -90%;
                right: calc(50% - 180px);
            }
            h1 {
                font-size: 150% !important;
            }
            p {
                font-size: 90% !important;
            }
        }
        @media (max-width:390px) {
            margin-bottom: 12px;
            .intro-header-blur-left {
                position: absolute;
                width: 60px;
                // top: -90%;
                left: calc(50% - 160px);
            }
            .intro-header-blur-right {
                position: absolute;
                width: 60px;
                // top: -90%;
                right: calc(50% - 160px);;
            }
            h1 {
                font-size: 150% !important;
            }
            p {
                font-size: 80% !important;
            }
        }
        h1 {
            font-family: sans-serif;
            font-weight: bold;
            color: #3b4945;
            font-size: 40px;
            font-style: normal !important;
        }
        p {
            color: #586662;
            font-size: 30px;
            font-weight: 400;
        }
    }
    .confide-topics-head {
        text-align: center;
        padding: 0;
        @media (max-width: 576px) {
            padding: 0;
            // p {
            //     font-size: 1.25rem !important;
            // }
        }
        // @media (max-width: 576px) {
        //     padding: 0;
        //     p {
        //         font-size: 1.25rem !important;
        //     }
        // }
    }
    
    .introduction-container2  {
        position: relative;
        .introduction-container-bg {
            min-height: 200px;
            background: url('/assets/img/backgrounds/ocean.jfif');
            background-repeat: no-repeat;
            background-size: 100% 100% ;
            border-radius: 10px;
            padding-left: 30px;
            filter: opacity(50%);
        }
        // margin-bottom: 40px;
        @media (max-width:576px) {
            // margin-bottom: 12px;
            font-size: 100%;

            .intro-title {
                font-size: 150% !important;
            }
            .intro-confide {
                font-size: 200% !important;
            }
            .introduction-container-bg {
                min-height: 130px;
            }
        }
        .intro-title {
            font-size: 200%;
            font-weight: bold;
            // font-family: 'emoji';
        }
        .intro-confide {
            font-style: italic;
            font-weight: bold;
            font-size: 300%;
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            // font-family: 'emoji';
        }
    }
    .confide-topics-items{
        gap: 0;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        .confidea-topic-card {
            flex: 20%;
            // background-image: url(../../../assets/img/backgrounds/flower_bg.svg);
            position: relative;
            font-size: 25px;
            .confidea-topic-card-bg {
                width: 100%;
            }
            .topic-img {
                position: absolute;
                width: 33%;
                top: 22%;
                left: 33%;
                transition: transform 1s ease-out;
            }
            .topic-title {
                p {
                    color: #3f57a2;
                    font-size: 1rem !important;
                    text-align: center;
                    @media (max-width: 1200px) {
                        font-size: 0.75rem !important;
                    }

                    // @media (max-width: 768px) {
                    //     font-size: 0.6rem !important;
                    // }
                    @media (max-width: 667px) {
                        font-size: 0.5rem !important;
                    }
                    // @media (max-width: 450px) {
                    //     font-size: 0.4rem !important;
                    // }
                }
            }
        }
        

        // .confidea-topic-card:hover {
        //     .topic-img {
        //         transform: rotateY(180deg)  !important;
        //     }
        // }
        @media (max-width: 1200px) {
            padding: 0 !important;
            .confidea-topic-card {
                // height: 450px;
                font-size: 20px;
            }
            // .topic-img {
            //     width: 120px;
            // }    
        }
        @media (max-width: 768px) {
            .confidea-topic-card {
                // flex: 20%;
                // height: 360px;
                font-size: 120%;
                // gap: 100px;
            }
        }
        @media (max-width:545px) {
            .confidea-topic-card {
                // height: 240px;
                font-size: 80%;
            }
            // .topic-img {
            //     top: 40px !important;
            //     width: 80%;
            // }
        }

    }
}

.section-title {
    h1 {
        font-family: League Gothic;
        color: #516292;
        font-size: 48.4px;
        font-style: italic;
    }
    p {
        font-family: sans-serif;
        color: #000000;
        font-size: 28px;
        text-align: left;
        font-style: italic;
    }
}

.introduction-container {
    .about-description-container {
        position: relative;
        padding-left: 40px;
        padding-right: 40px;
        background-color: #faf8f1;
        p.about-description{
            font-family: sans-serif;
            font-size: 27.5px;
            color: #46534f;
        }
        @media (max-width:1200px) {
            padding-left: 20px;
            padding-right: 20px;
        }
        @media (max-width:576px) {
            padding-left: 0;
            padding-right: 0;
            p.about-description {
                font-size: 20px;
            }
        }
    }
    .bg-item-flower {
        position: absolute;
        // transform: translateY(-100%) ;
        // rotate: 180deg;
        // left: 50px;
        left: calc(50% - 300px);
        width: 90px;
        top: calc(-50% + 5px);
    }
    .about-confideas-logo {
        top: -20px;
        left: 25%;
        width: 5%;
    }
    @media (max-width:945px) {
        padding-left: 0;
        padding-right: 0;
        .bg-item-flower {
            width: 80px;
        }
    }
    @media (max-width:800px) {
        padding-top: 20px;
    }
    @media (max-width:660px) {
        .bg-item-flower {
            width: 40px;
            left: calc(50% - 200px);
            top: 0;
        }
    }
    @media (max-width:450px) {
        .bg-item-flower {
            width: 40px;
            left: calc(50% - 150px);
        }
    }
}
.first-offer-section {
    padding: 100px;
    .section-title-row {
        padding-left: 0;
        padding-right: 0;
        position: relative;
        background-color: #7776ad;
        .first-offer-section-title {
            padding-left: 0;
            padding-right: 0;
            h1 {
                font-family: League Gothic;
                color: #fdeaac;
                font-size: 48.4px;
                text-align: center;
                font-style: italic;
            }
            p {
                text-align: center;
                font-size: 1.5rem;
                font-family: Arimo;
                color: #f6f2e7;
            }
            .first-offer-ribbon {
                width: auto;
                height: 70px;
                @media (max-width:576px) {
                    height: 50px;
                }
            }
            @media (max-width:865px) {
                p {
                    font-size: 1.25rem !important;
                }
            }
            @media (max-width: 700px) {
                h1 {
                    font-size: 200% !important;
                }
                p {
                    font-size: 1rem !important;
                }
            }
            @media (max-width:480px) {
                p {
                    font-size: 0.75rem !important;
                }
            }
        }

    }
    .first-offer-description-container.fist-offer-tips {
        padding: 30px 0 0 0;
        position: relative;
            p {
                text-align: right;
                margin-right: 16.67%;
                padding-top: 8px;
                font-family: sans-serif;
                font-size: 1.5rem;
                color: #7776af;
            }
            .sign-up-btn {
                // position: absolute;
                // right: 5px;
                // top: -20%;
                text-transform: none;
                background: #f1dd00;
                span {
                    font-size: 1rem;
                }
            }
            @media (max-width:876px) {
                padding: 20px 0 0 0;  
                p {
                    font-family: sans-serif;
                    font-size: 1rem;    
                }
                .sign-up-btn {
                    background: #f1dd00;
                    span {
                        font-size: 1rem;
                    }
                }
            }
            @media (max-width:768px) {
                .sign-up-btn {
                    width: 100%;
                    span {
                        font-size: 0.75rem;
                    }
                }
            }
            @media (max-width:576px) {
                padding: 10px 0 10px 0;
                p {
                    text-align: center;
                    margin-right: 0;
                    font-family: sans-serif;
                    font-size: 1rem;    
                }
                .sign-up-btn {
                    width: 100%;
                    span {
                        font-size: 0.75rem;
                    }
                }
            }

        // .first-offer-hard-item {
        //     position: absolute;
        //     bottom: 0;
        //     right: 0;

        //     @media (max-width:721px) {
        //         width: 20%;
        //         right: 50px !important;
        //     }
        //     @media (max-width: 950px) {
        //         width: 20%;
        //     }
        // }
    }
    @media (max-width:1110px) {
        padding-top: 20px;

        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        .bg-item-flower {
            width: 20%;
        }
        .bg-item-plant {
            width: 20%;
            bottom: -80px;
        }
    }
}

.satisfaction-section {
    padding: 0;
    p {
        text-align: left;
        font-family: sans-serif;
        font-size: 23px;
        color: #3b4945;
    }
    .section-title-row {
        position: relative;
        padding: 0;
        .section-title {
            align-items: center;
            img.satisfaction-sun {
                // position: absolute;
                top: -20px;
                left: 25%;
                width: 15%;           
            }
            img.satisfaction-gold-medal {
                // position: absolute;
                top: -20px;
                left: 25%;
                width: 15%;
            }
            h1 {
                font-family: League Gothic;
                color: #516292;
                font-size: 48.4px;
                font-style: italic;
            }
            p {
                font-size: 29px;
                font-family: Arimo;
                color: #f6f2e7;
            }    
        }
    }
    .tick-item {
        align-items: center;
        padding: 20px;
        img.satisfaction-tick {
            width: 8%;
        }
        @media (max-width:576px) {
            padding: 12px;
        }
        @media (max-width:450px) {
            padding: 8px;
        }
    }
}

.statistic-container {
    background-color: white;
}
.statistic-number {
    text-align: center;
    color: rgb(149,11,127);
    font-weight: 600;
    font-size: 2rem;
}

.top-advisor-container {
    background: linear-gradient(to right, rgb(233,187,255), rgb(247, 230, 255));
    padding: 20px;
    position: relative;
}
.top-advisor-container h3, h4 {
    color: #111;
}
.top-advisor-container .photo-container {
    border-radius: 40px;
}
.top-advisor-container .photo {
    background-image: url('../../../assets/img/backgrounds/top_advisor_bg2.webp');
    background-size: cover;
    padding:20px 20px 20px 20px;
    border-radius: 5px;
    height: 340px;
}
.top-advisor-container .photo img {
    height: 300px;
    width: auto;
    border-radius: 50%;
}
.top-advisor-container .intro-container {
    text-align: left;
}

.advisor-list-container {
    position: relative;
    // background: #fff;
    // background: #faf8f1;
    // background: url('../../assets/img/backgrounds/bg_yellow.png');
    padding-top: 5px !important;
    padding-bottom: 0px !important;

    .advisor-list-header-row {
        span {
            // font-family: sans-serif;
            font-size: 1rem;
            text-transform: none;
        }
        .section-title-bg-item {
            position: absolute;
            // top: 0;
            width: 80px;
            left: calc(50% - 250px);
            top: none;
            bottom: 10px;
        }
        .advisor-list-header-colume {
            padding-top: 20px;
            padding-bottom: 20px;
            position: relative;
        }
        @media (max-width:866px) {
            span {
                font-size: 1rem;
            }
            .advisor-list-header-colume {
                padding-bottom: 0px;
                position: relative;
            }
            .advisor-list-header-title {
                padding-top: 10px;
            }
            .section-title-bg-item {
                // width: 70px;
                bottom: 2px;
                left: calc(50% - 220px);
            }
            .h1 {
                padding-top: 10px;
            }
        }
        @media (max-width:768px) {
            .section-title-bg-item {
                width: 70px;
                left: calc(50% - 200px);
            }
            span {
                font-size: 0.75rem;
            }
        }
        @media (max-width:450px) {
            .section-title-bg-item {
                width: 60px;
                left: calc(50% - 160px);
            }
        }
    }

    @media (max-width:576px) {
        .advisor-list {
            justify-content: center;
        }
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .title {
        font-weight: bold;
        font-size:x-large;
    }
    .advisor-list-container-bg{
        position: absolute;
        top: 0px;
        z-index: -5;
    }
    .advisor-container-item {

        .advisor-name {
            color: #541354;
            font-weight: 700;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}



.recommended-advisor-section {
    .advisor-container-item {
        padding-left: 10px;
        padding-right: 10px;
    }
}


.card-img.img-thumbnail.img-fluid {
    background-color: #ebeb09 !important;
}

.receive-form {
    width: 100%;
    .input-group {
        background: #f6f2e7;
        border-radius: 8px;
        .form-control {
            margin: 20px 20px 20px 20px;
            border: none;
            border-radius: 4px;
            @media (max-width:576px) {
                margin: 10px 10px 10px 10px;
            }
        }
        .form-control-label {
            margin: 20px 20px 20px 20px;
            border: none;
            border-radius: 4px;
            @media (max-width:576px) {
                margin: 10px 10px 10px 10px;
            }
        }
    }
}

.advisor-card {
    // background-image: url('/assets/img/backgrounds/bg_yellow1.png');
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: top bot;
    // border-radius: 10px;
    position: relative;
    .advisor-card-bottom {
        padding: 20px;
        .advisor-name {
            font-size: 1.5em;
            font-weight: 700;
            margin-bottom: 10px;
            text-transform: uppercase;
        }
        .client-review {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.75rem;
            height: 5.4rem;
            text-align: left !important;
            line-clamp: 3;
            -webkit-line-clamp: 3;
            overflow: hidden;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }
        .client-name {
            text-align: left !important;
            font-weight: 500;
            font-size: 1.5em;
            line-height: 48px;
            letter-spacing: 1px;
        }
    }
    .avatar {
        width: 50%;
        height: auto;
        margin-top: -60px;
        overflow: hidden;
        border: 6px solid #fff;
        border-radius: 50%;
    }
    .card-up {
        height: 120px;
        overflow: hidden;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        // position: relative;
    }
    .card-title{
        overflow: hidden;
        // text-overflow: ellipsis;
        white-space: nowrap;
    }
    .aqua-gradient {
        background-image: url('/assets/img/backgrounds/bg_yellow.png');
    }
    .card-body {
        p {
            text-align: center;
        }
    }
}

.why-choose-us-container {
    background: linear-gradient(to right, #ffd977, rgba(254, 241, 149,0.5));
    position: relative;
    margin-top: 20px;
    margin-bottom: 100px;

    .why-choose-us-container2 {
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;

        @media (max-width:576px) {
            padding-top: 90px;
            .flower1 {
                width: 200px !important;
                top: -100px !important;
            }
        }
        .flower1 {
            position: absolute;
            top: -110px;
            right: 100px;
            width: 250px;
            opacity: 0.4;
        }
        .flower2 {
            position: absolute;
            right: calc(30%);
            bottom: -100px;
            width: 200px;
            height: auto;
            opacity: 0.8;
        }
    }
}

.natal-chart-container {
    background-color: rgb(242,238,244);
    padding-top: 50px;   
    padding-bottom: 50px;

    .personal-info {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 12px rgba(0,0,0,.3);
    }

    img.img-natal-chart {
        height: 280px;
    }
}

.consult-area-container {
    background-color: white;
    padding: 50px 20px 50px 20px;

    .consult-area {
        padding: 20px;
    }
}

.consult-area {
    box-shadow: 0px 0px 12px rgba(0,0,0,.3);
    border-radius: 5px;
    img {
        width: 100%;
    }
    .description {
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 4;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
}

.main-body-section section {
    padding: 20px;
    h1 {
        font-family: League Gothic;
        color: #516292;
        font-size: 40px;
        font-style: italic;
    }
    p {
        font-family: sans-serif;
        color: #2e2e2e;
        font-size: 1.25rem;
        text-align: left;
    }
    @media (max-width:800px) {
        padding: 0;
        h1 {
            font-size: 200% !important;
        }
        p {
            font-size: 1.0rem !important;
        }
        .section-title-bg-item {
            width: 60px;
        }
    }
    @media (max-width:576px) {
        padding: 0;
        h1 {
            font-size: 200% !important;
        }
        p {
            font-size: 1.1rem !important;
        }
        .section-title-bg-item {
            width: 60px;
        }
    }
    @media (max-width:450px) {
        padding: 0;
        h1 {
            font-size: 200% !important;
        }
        p {
            font-size: 1rem !important;
        }
        .section-title-bg-item {
            width: 60px;
        }
    }
}


.become-advisor-section {
    // .section-container {
    //     padding: 30px 0 30px 0;
    // }
    .apply-btn {
        background: #17499a;
        border-radius: 12px;
        border: none;
        padding: 0;
        padding-top: 5px;
        cursor: pointer;
        outline-offset: 8px;
        transition: transform 250ms;
        overflow:visible !important;

        @media (max-width: 576px) {
            border-radius: 6px;
        }

        .apply-span {
            display: block;
            padding: 10px 20px 5px 20px;
            border-radius: 12px;
            font-size: 1rem;
            background: #3967b0;
            color: white;
            transform: translateY(-8px);
            @media (max-width: 576px) {
                padding: 5px 21px;
                border-radius: 6px;
                font-size: 0.75rem;   
                transform: translateY(-4px); 
            }
        }
    }
    .apply-btn:active {
        .apply-span {
            transform: translateY(-2px);
        }
    }
    .section-title-row {
        h1 {
            padding: 20px 0 20px 0;
        }
        // p: {
        //     padding: 20px 0 20px 0;
        // }
    }
}

.how-chat-section {
    .section-container { 
        max-width: 1320px;

        .why-choose-description {
            p.why-choose-description-subtitle {
                margin-bottom: 0;
                text-align: center;
                font-family: League Gothic;
                color: #516292;
                font-size: 1.5rem;
                font-style: italic;
            }
            p.why-choose-description-text {
                margin-bottom: 0;
                // font-size: 1.25rem;
            }
            @media (max-width: 1200px){
                p.why-choose-description-subtitle {
                    text-align: center;
                    font-family: League Gothic;
                    color: #516292;
                    font-size: 1.25rem !important;
                    font-style: italic;
                }
                p.why-choose-description-text {
                    font-size: 0.75rem !important;
                }
            }

            @media (max-width:768px){
                p.why-choose-description-subtitle {
                    font-size: 1.5rem !important;
                    margin-bottom: 0;
                }
                p.why-choose-description-text {
                    font-size: 1rem !important;
                    margin-bottom: 0.2rem;

                }            
            }
            // @media (max-width:450px){
            //     p.why-choose-description-subtitle {
            //         font-size: 1rem !important;
            //     }            
            // }  
        }
        .start-chart-column {
            position: relative;
            .how-chat-img {
                position: absolute;
                top: calc(50% + 50px);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 100%;
                @media (max-width:768px){
                    position: unset;
                    top: 0;
                    -ms-transform: none;
                    transform: none;
                    width: 100%;
                }
            }
            @media (max-width:768px){
                position: unset;
            }    
        }
        .section-title-row {
            .section-title {
                @media (max-width:1200px){
                    h1 {
                        font-size: 1.75rem;
                    } 
                }
            }
        }
        @media (max-width:768px){
            max-width: none;
        }

        @media (max-width:992px){
            max-width: none;
        }
        @media (max-width:1200px){
            max-width: 1140px;
        }
    }
}


.box{
    // width: calc(33% - 10px);
    position: relative;
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    border-radius: 5px;
    box-shadow: 0px 0px 12px rgba(0,0,0,.3);
    @media (max-width:768px){
        padding: 0;
    }
}

.box .image{
    margin: 10px 0;
    width: 150px;
    height: auto;
    background: #ffe658;
    padding: 3px;
    border-radius: 50%;
    box-shadow: 0px 0px 12px rgba(0,0,0,.3);
    @media (max-width:768px){
        width: 40%;
    }
}
  .box .image img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fff;
  }
section .box p{
    height: 100px;
    padding: 0 8px 0 8px;
    text-align: left;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    @media (max-width: 768px) {
        font-size: 10px !important;
        height: 60px;
    }
  }
  .box .name_job{
    margin: 10px 0 3px 0;
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: 768px) {
        font-size: 12px;
    }
  }
  .rating i{
    font-size: 18px;
    color: #f5d000;
    margin-bottom: 5px;
    @media (max-width: 768px) {
        font-size: 12px;
    }
  }
.box .btns{
    position: absolute;
    bottom: 8px;
    padding: 8px;
    background-color: #ffffff;    
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 100%;
    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.flicking-arrow-prev.is-circle, .flicking-arrow-next.is-circle {
    background-color: #edd1b7;
    border-radius: 50%
}

.flicking-arrow-prev {
    left: 10px;
}

.flicking-arrow-prev, .flicking-arrow-next {
    position: absolute;
    top: 50%;
    width: 64px;
    height: 64px;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
    @media (max-width: 768px) {
        width: 32px;
        height: 32px;
    }
}

.flicking-arrow-prev.is-circle::before, .flicking-arrow-prev.is-circle::after, .flicking-arrow-next.is-circle::before, .flicking-arrow-next.is-circle::after {
    background-color: white;
}

.flicking-arrow-prev::before {
    top: 50%;
    left: 22px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    @media (max-width: 768px) {
        left: 11px;
    }
}
.flicking-arrow-prev::after {
    top: calc(50% - 4px);
    left: 22px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    @media (max-width: 768px) {
        left: 11px;
    }
}
.flicking-arrow-next::before {
    top: 50%;
    right: 22px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    @media (max-width: 768px) {
        right: 11px;
    }
}
.flicking-arrow-next::after {
    top: calc(50% - 4px);
    right: 22px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    @media (max-width: 768px) {
        right: 11px;
    }
}
.flicking-arrow-prev::before, .flicking-arrow-prev::after, .flicking-arrow-next::before, .flicking-arrow-next::after {
    content: "";
    width: 24px;
    height: 6px;
    position: absolute;
    background-color: #edd1b7;
    @media (max-width: 768px) {
        width: 12px;
        height: 3px;
    }
}
