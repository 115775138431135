
.chat-history-container {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    padding: 0px;
    @media (max-width:768px) {
        flex-direction: column;
    }

    .message-history-container {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 250px;
        display: flex;
        flex-direction: column;
        background-color: rgba(255,255,0,0.05);

        @media (max-width:768px) {
            top: 60px;
            right: 0px;
        }

        &::before {
            content: "";
            background-image: url(../../assets/img/backgrounds/chatroom_bg.png);
            background-repeat:repeat;
            opacity: 0.6;
            z-index: -1;
            position: absolute;
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
        }

        .message-history-container2 {
            position: absolute;
            left: 5px;
            top: 10px;
            right: 0px;
            bottom: 30px;
            overflow-x: hidden;
            overflow-y: auto;
            flex: 1;
            transition: scroll-behavior 1s;
            scroll-behavior: smooth;

            display: flex;
            flex-direction: column;

            scrollbar-width: thin;
            &::-moz-scrollbar-thumb {
                background-color: #888; /* Set the color of the scrollbar thumb for Firefox */
            }
            &::-moz-scrollbar-track {
                background-color: #f1f1f1; /* Set the color of the scrollbar track for Firefox */
            }

            &::-webkit-scrollbar {
                width: 5px; /* Set the width of the scrollbar */
            }
            &::-webkit-scrollbar-thumb {
                background-color: #b55e5e; /* Set the color of the scrollbar thumb for Chrome and Safari */
            }
            &::-webkit-scrollbar-track {
                background-color: #f1f1f1; /* Set the color of the scrollbar track for Chrome and Safari */
            }

            > div:first-child {
                margin-top: auto;                   // for the same effect as justify-content-end
            }

            .one-message {
                width: 70%;
                @media (max-width:992px) {
                    width: 90%;
                }

                .content {
                    position: relative;
                    padding-bottom: 10px !important;
                    max-width: calc(100% - 55px);
                    .msg-time {
                        position: absolute;
                        bottom: 5px;
                        right: 5px;
                        font-size: small;
                        color: rgb(14, 122, 211);
                    }
                }
                &.partner .content {
                    padding-right: 60px;
                }
                &.mine .content {
                    padding-right: 70px;
                }

                .avatar {
                    width: 40px;
                    height: 40px;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        @media (max-width: 768px) {
                            display: none;
                        }
                    }
                }
                .content {
                    padding: 10px 20px;
                    border-radius: 10px;
                    overflow-wrap: break-word;
                }
                &.mine .content {
                    background-color: #FFECC7;
                }
                &.partner .content {
                    background-color: #E8E1FF;
                }
            }
        }
    }

    .chat-metainfo,
    .chat-metainfo-admin
    {
        position: absolute;
        top:0px;
        bottom: 0px;
        right: 0px;
        width: 250px;
        padding: 10px;
        padding-bottom: 30px;
        background: linear-gradient(45deg, #f4e8bebf, #f4f0e0);
        box-shadow: 0px 0px 6px rgba(0,0,0,0.2);

        .label {
            width: 90px !important;
            color: #555;
        }
        .other-info-value {
            color: #0378cb;
        }
        .chat-partner {
            width: 100%;
            border-radius: 10px;
            // background-color: #fafafa;
            // box-shadow: 0 0 6px rgba(0,0,0,0.2);

            img {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                border: 5px solid rgb(237, 237, 95);
            }
            &.advisor img {
                &:hover {
                    margin-top: -2px;
                    border-color: pink;
                    cursor: pointer;
                }
            }
        }
        .chat-client,
        .chat-advisor {
            width: 100%;
            background-color: #fafafa;
            border-radius: 10px;
            box-shadow: 0 0 6px rgba(0,0,0,0.2);

            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                border: 5px solid rgb(237, 237, 95);
            }
        }
        .other-infos {
            background-color: #fafafa;
            border-radius: 10px;
            padding: 10px;
            box-shadow: 0 0 6px rgba(0,0,0,0.2);
        }
    }

    .chat-metainfo-sm {
        background: linear-gradient(45deg, #f4e8bebf, #f4f0e0);
        border-radius: 5px;
        border: 1px solid rgb(200, 179, 41);
        z-index: 10;

        .partner-avatar {
            img {
                border: 2px solid rgb(205, 192, 50);
            }
        }
        .partner-username {
            color: purple;
        }
        .ad-earning {
            border-radius: 5px;
            border: 2px solid goldenrod;
            width: fit-content;
            font-size: 90%;
        }
        .chat-date {
            font-size: 90%;
        }
    }
}
