.coupon-chat-container {
    .coupon-chat {
        max-width: 600px;
        padding: 20px 20px 30px 20px;
        min-height: 200px;
        border-radius: 5px;
        background: #d5f7d5;
        box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
        .service-info {
            background: rgb(253, 234, 252);
            box-shadow: 1px 1px 3px rgba(121, 0, 83, 0.3);
            border-radius: 5px;
            padding: 15px;
            .header-title {
                color: #555;
                .advisor {
                    color: rgb(206, 27, 206);
                }
            }
            label {
                width: 100px;
            }
            .value {
                width: calc(100% - 110px);
                &.message-content {
                    overflow-wrap: break-word;
                }
                &.expire-at {
                    color: purple;
                }
            }
        }
        .advisor-container {
            width: 90%;
            max-width: 300px;
        }
        .error {
            font-size: 150%;
            font-weight: bold;
        }
    }
    .action {
        width: 100%;
        max-width: 600px !important;
    }
}
