.advisors-list,
.advisor-pending-list
{
    .advisor-avatar {
        &.online {
            border: 3px solid rgb(2, 171, 2);
        }
        &.offline {
            border: 3px solid #ccc;
        }
    }
    .total-earning {
        padding: 0px 10px !important;
        border-radius: 5px;
        border: 1px solid gold;
    }
    .list-status {
        border-radius: 5px;
        padding: 2px 10px !important;
        background: pink;
        color: #555;
        font-size: 80%;
        &.listing {
            background: green;
            color: white;
        }
    }
}

.admin-advisor-show-container{
    .advisor-service-container {
        box-shadow: 0px 0px 6px rgba(0,0,0,0.12);
        padding: 20px 30px;
        @media (max-width:576px) {
            padding: 10px;
        }
        .avatar {
            img {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                border: 5px solid rgb(235, 235, 12);
                transition: border-color 0.3s, margin 0.3s;
                &:hover {
                    margin-top: -2px;
                    border-color: pink;
                }
                @media (max-width:576px) {
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }

    .accordion-item-title {
        padding-left: 5px;
    }

    .application-document-container {
        img {
            width: 200px;
            height: 150px;
            max-width: 100%;
            border: 3px solid rgb(255, 190, 104);
            border-radius: 10px;
        }
        .id-card {
            box-shadow: 0px 0px 6px rgba($color: #000000, $alpha: 0.3);
            background-color: rgb(240, 242, 249);
        }
        .cert-files {
            box-shadow: 0px 0px 6px rgba($color: #000000, $alpha: 0.3);
            background-color: rgb(249, 240, 249);
            padding-bottom: 20px;
            .cert-file {
                .img-container {
                    position: relative;
                    .cert-delete {
                        position: absolute;
                        top: 5px;
                        right: 10px;
                        &:hover{
                            opacity: 0.9;
                            margin-top: -1px;
                        }
                    }
                }
            }
        }
        .tax-file {
            box-shadow: 0px 0px 6px rgba($color: #000000, $alpha: 0.3);
            background-color: rgb(240, 249, 240);
            padding-bottom: 20px;
            img {
                width: 150px;
                height: 200px;
            }
        }
    }

    .user-memo {
        background-color: #ecf7ff;
        border-radius: 5px;
        border: 1px dashed green;
        padding: 10px;
    }
}

.advisors-list,
.advisor-pending-list,
.admin-advisor-show-container
{
    .online-status,
    .pending-type
    {
        padding: 1px 10px;
        border-radius: 5px;
        text-align: center;
        &.online {
            background-color: green;
            color: white;
        }
        &.offline {
            background-color: #ddd;
            color: #aaa;
        }
        &.insert {
            background-color: rgb(224, 86, 86);
            color: white;
        }
        &.update {
            background-color: pink;
            color: #333;
        }
    }
    .apply-time {
        font-size: 90%;
    }
    
    .user-status {
        padding: 3px 5px;
        border-radius: 5px;
        text-align: center;
        width: 90px;
        &.approved {
            background-color: green;
            color: #eee;
        }
        &.waiting {
            background-color: pink;
            color: #333;
        }
        &.suspended {
            background-color: rgb(213, 7, 7);
            color:#eee;
        }
    }
}

.updated {
    &::after {
        content: ' (updated) '; 
        color: red;
    }
}