.float-go-back-bar {
    position: fixed;
    left: 0px !important;
    top: 0px !important;
    z-index: 50;
    background: white;
    width: 100%;
    transition: all 0.5s;
    margin-left: auto;
    margin-right: auto;
    background: #f5faff;
    border-bottom: 1px solid #dacaff;
  
    svg.go-back-icon {
      width: 18px !important;
      height: 18px !important;
      fill: #d566fd !important;
    }
  }