@import '../../variable';

.chat-container1 {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;

    .chat-container2 {
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        box-shadow: 0px 0px 6px rgba(0,0,0,.3);

        // &.iOS {
        //     bottom: 40px
        // }

        .chat-input-history-container {
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 250px;
            display: flex;
            flex-direction: column;
            background-color: rgba(255,255,0,0.05);
            &::before {
                content: "";
                background-image: url(../../assets/img/backgrounds/chatroom_bg.png);
                background-repeat:repeat;
                opacity: 0.6;
                z-index: -1;
                position: absolute;
                left: 0px;
                top: 0px;
                right: 0px;
                bottom: 0px;
            }
            @media (max-width: 992px) {
                right: 0px;
            }

            .message-history-container {
                position: absolute;
                left: 0;
                right: 0;
                .error-msg {
                    position: absolute;
                    top:5px;
                    @media (max-width:992px) {
                        top: 60px !important;
                    }
                }
                .scroll-up-btn, .scroll-down-btn {
                    z-index: 10;
                    width: 25px;
                    height: 25px;
                    right: 20px;
                    border-radius: 50%;
                    border: 2px solid #367e34;
                    position: absolute;
                    opacity: 0.8;
                }
                .scroll-up-btn {
                    top: calc(40%);
                }
                .scroll-down-btn {
                    top: calc(60%);
                }
                .message-history-container2 {
                    position: absolute;
                    left: 5px;
                    top: 5px;
                    right: 0px;
                    bottom: 25px;
                    overflow-x: hidden;
                    overflow-y: auto;
                    flex: 1;
                    transition: scroll-behavior 1s;
                    scroll-behavior: smooth;

                    display: flex;
                    flex-direction: column;

                    scrollbar-width: thin;
                    &::-moz-scrollbar-thumb {
                        background-color: #888; /* Set the color of the scrollbar thumb for Firefox */
                    }
                    &::-moz-scrollbar-track {
                        background-color: #f1f1f1; /* Set the color of the scrollbar track for Firefox */
                    }

                    &::-webkit-scrollbar {
                        width: 5px; /* Set the width of the scrollbar */
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #b55e5e; /* Set the color of the scrollbar thumb for Chrome and Safari */
                    }
                    &::-webkit-scrollbar-track {
                        background-color: #f1f1f1; /* Set the color of the scrollbar track for Chrome and Safari */
                    }

                    > div:first-child {
                        margin-top: auto;                   // for the same effect as justify-content-end
                    }

                    .one-message {
                        max-width: 70%;
                        font-size: 120%;
                        @media (max-width:992px) {
                            max-width: 90%;
                        }
                        .content {
                            position: relative;
                            padding-bottom: 10px !important;
                            .msg-time {
                                position: absolute;
                                bottom: 5px;
                                right: 5px;
                                font-size: small;
                                color: rgb(14, 122, 211);
                            }
                        }
                        &.partner .content {
                            padding-right: 60px;
                        }
                        &.mine .content {
                            padding-right: 70px;
                        }
        
                        .avatar {
                            width: 40px;
                            height: 40px;
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                @media (max-width: 768px) {
                                    display: none;
                                }
                            }
                        }
                        .content {
                            max-width: calc(100% - 55px);
                            padding: 10px 20px;
                            border-radius: 10px;
                            overflow-wrap: break-word;
                            @media (max-width: 992px) {
                                max-width: calc(100% - 15px);
                            }
                        }
                        &.mine .content {
                            background-color: #FFECC7;
                        }
                        &.partner .content {
                            background-color: #E8E1FF;
                        }
                    }
                }

                .partner-is-typing {
                    position: absolute;
                    bottom: 0px;
                    left: 20px;
                    &::after{
                        padding-left: 2px;
                        content: '';
                        animation-name: istyping-animation;
                        animation-duration: 1.5s;
                        animation-iteration-count: infinite;
                        animation-timing-function: linear;
                        animation-direction:normal;
                    }
                }
                .show-new-message-notify {
                    position: absolute;
                    bottom: 0px;
                    left: calc(50% - 60px);
                    border-radius: 5px;
                    padding: 5px 15px;
                    color: white;
                    font-size: 80%;
                    z-index: 10;
                    color:"#ff00ff";
                    animation-name: new_message_notify;
                    animation-duration: 1s;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                    animation-direction:alternate;
                }
            }
            
            .chat-input-container {
                position: absolute;
                left: 0;
                right: 0;
                padding: 5px 15px 10px 15px;
                .scrollbar {
                    border-radius: 10px;
                }
                .inputContentEditable {
                    font-size: 120%;
                    padding: 15px 20px;
                    background-color: white;
                    word-break: break-word;
                    white-space: pre-wrap;
                    outline: none;
                }

                .send-btn {
                    background-color: #f9a65b;
                    padding: 10px;
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .chat-partner-container {
            position: absolute;
            top:0px;
            bottom: 0px;
            right: 0px;
            width: 250px;
            background: linear-gradient(45deg, #f5eebd, #f4f0e0);
            box-shadow: 0px 0px 6px rgba(0,0,0,0.2);

            .chat-session-name {
                font-size: 90%;
            }

            .page-primary-btn {
                width: 100%;
                background: #fffce5;
                color: #333;
                font-size: 90%;
                box-shadow: 1px 1px 3px rgba(145, 95, 9, 0.5);
                &:hover {
                    box-shadow: 3px 3px 3px rgba(145, 95, 9, 0.5);
                }
            }

            .page-normal, .page-edit-note {
                position: absolute;
                left: 0;
                right: 0;
                top: 0px;
                bottom: 0;
            }
            .page-normal {
                padding: 0px 10px 30px 10px;
                .timer {
                    // background: rgb(236, 213, 236);
                    border-radius: 5px;
                    img {
                        height: 30px;
                    }
                    .spent-time {
                        width: 100px;
                        font-size: 150%;
                        color: #333;
                        &.coupon_session {
                            color: purple !important;
                        }
                    }
                }
                .free-minutes {
                    font-size: 120%;
                    .icon {
                        background: url(/assets/img/icons/png/discount.png);
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        width: 45px;
                        height: 45px;
                        color: purple;
                    }
                    .value {
                        color: green;
                    }
                }
                .action {
                    .edit-note-btn {
                        position: relative;
                        &::after {
                            content: '>';
                            position: absolute;
                            right: 15px;
                        }
                    }
                }
            }
            .page-edit-note {
                padding: 20px 10px 10px 10px;
                .header {
                    cursor: pointer;
                    color: #555;
                    padding:5px;
                    border-radius: 5px;
                    box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
                    &::after {
                        content: '>';
                        position: absolute;
                        right: 25px;
                    }
                    &:hover {
                        box-shadow: 2px 2px 3px rgba(0,0,0,0.5);
                    }
                }
                .client-note {
                    background-color: #eee;
                }
            }
            .page-normal, .page-edit-note {
                .photo {
                    position:relative;
                    padding: 20px;
                    border-radius: 10px;
                    img{
                        width: 100%;
                        height: 190px;
                        border-radius: 50%;
                        border: 5px solid rgb(231 215 91);
                        &.waiting-partner {
                            border: 5px solid #aaa;
                            animation-name: wait-partner-animation;
                            animation-duration: 1s;
                            animation-iteration-count: infinite;
                            animation-timing-function: linear;
                            animation-direction: alternate;
                        }
                    }
                    .name {
                        font-size: 130%;
                        font-weight: normal;
                        color: purple;
                    }
                }
            }
        }

        .chat-partner-container-sm {
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            padding: 5px 10px;
            background-color: #024089;
            border-bottom: 2px solid #c4b56a;
            z-index: 100;

            .chat-session-name {
                font-size: 80%;
                color: #e3ff0b;
            }
            img.waiting-partner {
                animation-name: wait-partner-animation;
                animation-duration: 1s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                animation-direction: alternate;
            }
            .photo {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                border: 3px solid $primary-yellow-color;
            }
            .partner-name {
                color: #fff !important;
            }
            .free-minutes {
                background: url(/assets/img/icons/png/discount.png);
                background-size: 100% 100%;
                background-repeat: no-repeat;
                width: 30px;
                height: 30px;
                color: purple;
                font-size: 90%;
            }
            .action {
                .timer {
                    // background-color: pink;
                    padding: 0px 5px;
                    border-radius: 5px;
                    img {
                        height: 25px;
                    }
                    .spent-time {
                        font-size: 90%;
                        color: white;
                        padding-top: 1px;
                        &.coupon_session {
                            color: greenyellow;
                        }
                    }
                }
                .end-chat-btn, .edit-note {
                    cursor: pointer;
                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 5px;
                    }
                }
            }
        }

        .client-notes {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0px;
            width: 500px;
            max-width: 100%;
            border-left: 1px solid #ddd;
            padding: 10px;
            background-color: #fafafa;
            transition: display 0.5s ;
            border-radius: 10px;
            @media (max-width: 992px) {
                top: 60px;
            }
            .key-note-section {
                background: rgb(250, 232, 200);
                border-radius: 5px;
                padding: 10px;
            }
            .chat-notes-section {
                .chat-note {
                    border-radius: 10px;
                    padding: 10px;
                    border: 1px dashed;
                    background: #fffcf2;
                }
            }
        }
    }
}

.lack-balance-modal {
    .modal-dialog {
        max-width: 450px !important;
        .current-balance {
            .balance {
                color: rgb(13, 166, 23);
                font-weight: 400;
            }
        }
        .amount-select-card {
            border-radius: 10px;
            padding-bottom: 40px !important;
            .duration-select {
                .chat-duration-card {
                    // background-image: linear-gradient(#f2eafa, #debcfb);
                    box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
                    cursor: pointer;
                    height: 150px;
                    max-width: 115px;
        
                    &:hover {
                        border: 1px solid rgb(105, 180, 105);
                    }
                    &.selected {
                        background-image: linear-gradient(#d3a8f9, #8906fb);
                        .duration-info {
                            .chat-time, .money {
                                color: white;
                            }
                            .unit-minute {
                                color: #ddd;
                            }
                        }
                    }
                    .duration-info {
                        .chat-time {
                            font-size: 300%;
                            line-height: 40px;
                            color: rgb(148, 75, 250);
                            font-family: 'fantasy';
                            font-weight: bold;
                        }
                        .unit-minute {
                            text-decoration: underline;
                            text-decoration-skip-ink: auto;
                            padding-bottom: 2px;
                            color: rgb(159, 102, 238);
                        }
                        .money {
                            font-weight: bold;
                            color: rgb(148, 75, 250);
                            font-size: 110%;
                        }
                    }
                }
            }
            .action {
                margin-top: 40px;
            }
        }
    }
    .spent-time {
        font-size: larger;
        color: purple;       
    }
    .message {
        &.success {
            color: green;
        }
    }
}

.lack-balance-advisor-modal {
    .animation {
        img {
            width: 80px;
            height: auto;
        }
        .spent-time {
            font-size: 200%;
            font-weight: bold;
            color: purple
        }
    }
}

@keyframes istyping-animation {
    0% {
        content: '';
    }
    30% {
        content: '.';
    }
    60% {
        content: '..';
    }
    100% {
        content: '...';
    }
}
@keyframes new_message_notify {
    0% {
        background: #ff00ff;
    }
    100% {
        background: rgb(233, 120, 250);
    }
}

@keyframes wait-partner-animation {
    from {
        border-color: rgb(186, 236, 112);
        opacity: 0.3;
    }
    to {
        border-color: #aaa;
        opacity: 1;
    }
}