.admin-pending-payout-list {
    table tbody tr {
        cursor: pointer;
    }
}

.payout-processing {
    padding: 5px 10px;
    border-radius: 5px;
    background: #f7d1d7;
    color: #460808;
}

.admin-pending-payout-detail {
    .total-amount {
        padding: 0px 20px;
        border-radius: 5px;
        border: 1px #5c8986 solid;
        color: green;
        font-size: 150%;
        font-weight: bold;
        background-color: rgb(235,255,254);
    }
}