.admin-chat-list {
    .chats-table {
        .chat-status {
            padding: 3px 10px;
            border-radius: 5px;
            text-align: center;
            &.in_chatting {
                background-color: rgb(68, 145, 68);
                color: white;
            }
            &.canceled, &.rejected {
                background-color: pink;
                color: #aaa
            }
            &.ended {
                background-color: #eee;
                color: #aaa;
            }
        }
    }
}