@import 'variable';

.nav-tabs .nav-link.active {
  --mdb-nav-tabs-link-active-color: #1b5877;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: #222 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (min-width: 992px) {
    font-size: 16px !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco,  Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-yellow: #ebeb0c;
}

.flex-fluid {
  flex: 1;
}
.w-100px {
  width: 100px !important;
}
.w-150px {
  width: 150px !important;
}
.w-200px {
  width: 200px !important;
}
.w-90 {
  width: 90% !important;
}
.w-80 {
  width: 90% !important;
}
.w-70 {
  width: 70% !important;
}
.w-60 {
  width: 60% !important;
}
.w-50 {
  width: 50% !important;
}
.w-45 {
  width: 45% !important;
}
.w-40 {
  width: 40% !important
}
.w-30 {
  width: 30% !important
}
.w-fit-content {
  width: fit-content !important;
}

.h-100 {
  height: 100% !important;
}

.hidden {
  display: none !important;
}

.error {
  color: #FF5A60;
}
.notify {
  font-weight: normal;
  font-size: 14px;
  color: brown;
}
.comment {
  font-weight: normal;
  font-size: 14px;
  color: #888;
}
.img-icon {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.underline-bar {
  position: relative;
  &::after {
    content: '';
    position: absolute !important;
    bottom: 2px;
    left: 0px;
    background-color: purple;
    height: 1px !important;
    width: 100% !important;
  }
}

.PhoneInput{
  border: 1px solid #ced4da !important;
}
.PhoneInput .PhoneInputInput:focus{
  box-shadow: none;
  outline-width: 0 !important;
}
.form-control input {
  border: none !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker {
  .react-datepicker__header {
    background-color: #f3f3c6;
    .react-datepicker__header__dropdown {
      select {
        border-radius: 5px;
        padding: 5px;
        margin-top: 5px;
      }
    }
  }
}

.accordion-item .collapse {
  height: fit-content !important;
}

.required {
  &::after {
    content: ' (*) '; 
    color: #FF5A60;;
  }
}

label {
  color: #7d7d7e;
  font-size: 90%;
}

.confideas-primary-btn {
  background-color: #fbe953 !important;
  color: #333 !important;
}

.confideas-table-header {
  display: flex;
  align-items: center;
  margin: 10px 0 10px 20px;
  .total-count {
    font-size: 120%;
    display: flex;
    align-items: center;
  }
  .search-bar {
    .search-item {
      min-width: 100px !important;
      max-width: 250px !important;
      &.search-term {
        display: flex !important;
        align-items: center;
      }
    }
  }

  @media (max-width: 576px) {
      .search-bar {
        .search-term {
            margin: 0 5px 0 0 !important;
            input {
                max-width: 150px;
            }
        }
      }
  }
}

table.confideas-table {
  tr {
    &:hover {
      background: #eee;
    }
  }
}

.circle-avatar-50 {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid #ddd;
}
.circle-avatar-40 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
}
.circle-avatar-30 {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
}
.circle-avatar-150 {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 1px solid #ddd;
}
.circle-avatar-100 {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
}
.circle-avatar-80 {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 1px solid #ddd;
}
.circle {
  border-radius: 50%;
}

textarea {
  border-radius: 5px;
}

.font-italic {
  font-style: italic !important;
}
.fs-14px {
  font-size: 14px !important;
}
.fs-200 {
  font-size: 200% !important;
}
.fs-150 {
  font-size: 150% !important;
}
.fs-120 {
  font-size: 120% !important;
}
.fs-90 {
  font-size: 90% !important;
}
.fs-80 {
  font-size: 80% !important;
}
.fw-400 {
  font-weight: 400 !important;
}

.tooltip-inner {
  max-width: none !important;
}

.popover {
  inset: 10px auto auto -40px !important;
}

.btn {
  font-size: 14px !important;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}

.fixed-action-bar {
  @media (max-width: 768px) {
      position: fixed !important;
      left: 0;
      right: 0;
      bottom: 0px;
      z-index: 100;
      display: flex;
      justify-content: center;
      background: none;
      padding: 12px;
  }
}

.text-underline {
  text-decoration: underline !important;
}

.banned-word {
  color: #FF5A60;
}

.text-blue1 {
  color: #0977d9 !important;
}
.text-purple {
  color: purple !important;
}
.text-gray1 {
  color: #888;
}
.text-gray555 {
  color: #555 !important;
}
.text-red {
  color: red;
}
.text-amber1 {
  color: #ffbf00;
}

.underline {
  position:relative;
  &::after {
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    bottom: 0px;
    content: '';
    border-bottom: 1px solid #ddd;
  }
}
.underline-5px {
  &::after {
    bottom: -5px !important;
  }
}

.coupon {
  background: url(/assets/img/icons/png/discount.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: green;
  font-weight: bold;
}

@media (min-width: 768px) {
  .me-md-8 {
    margin-right: 3rem !important;
  }
}

@media (max-width: 576px) {
  .card-body {
    padding: 10px !important;
  }
  .w-sm-100px {
    width: 100px !important;
  }
  table.table {
    td {
      padding: 8px !important;
    }
    th {
      padding: 8px !important;
    }
  }
}

.go-back-bar {
  position: absolute;
  left: 10px;
  top: 5px;
  z-index: 10;
  svg.go-back-icon {
    width: 18px !important;
    height: 18px !important;
    fill: #d566fd !important;
  }
  @media (min-width:768px) {
    top: 25px !important;
    left: 25px !important;
  }
}

.go-back-icon {
  width: 18px !important;
  height: 18px !important;
  fill: #d566fd !important;
}