@import '../../variable.scss';

.chat-all-history-container {
    .title {
        padding-left: 20px;
        font-weight: bold;
        font-size: 120%;
    }
    .search-partner-input {
        width: 300px !important;
    }

    .chat-history {
        overflow: auto;
        table {
            tbody {
                tr {
                    &:hover {
                        background: #f5f5f5;
                    }
                    .partner {
                        img {
                            width: 40px;
                            border-radius: 50%;
                        }
                    }
                    .billing {
                        &.billed {
                            color: $success-color;
                        }
                        &.pending {
                            color: #333;
                        }
                    }
                }
            }
        }
    }
}