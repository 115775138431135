.client-checkout-container {
    .you-will-be-chared {
        border-radius: 5px;
        border: 1px dashed #022ca1;
        background: #fefefe;
    }
    .message {
        &.success {
            color: green;
        }
    }
}
