.login-modal {
    @media (max-width: 400px) {
        .forgot-password {
            flex-direction: column;
            .reset-password {
                justify-content: start !important;
            }
        }
        .sign-up {
            flex-direction: column;
        }
    }
    .forgot-password {
        .reset-password {
            text-decoration: underline;
            color: rgb(0, 70, 128);
            cursor: pointer;
        }
    }
}

.pay-amount-modal {
    .current-balance {
        color: rgb(35, 173, 31) !important;
        font-weight: bold;
    }
    .modal-dialog {
        // max-width: 450px !important;
        .modal-body {
            padding-bottom: 30px !important;
            .advisor-info-card {
                .advisor-avatar{
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                    border: 5px solid rgb(245, 234, 107);
                    margin-right: 20px;
                    @media (max-width:576px) {
                        width:100px;
                        height: 100px;
                        margin-right: 10px;
                    }
                }    
                .main-info {
                    .username {
                        font-weight: bold;
                        color: purple;
                    }
                }
            }
            .amount-select-card {
                // background-image: linear-gradient(rgb(248, 241, 249), rgb(245, 216, 246));
                border-radius: 10px;
                .you-pay-amount {
                    position: absolute;
                    top: -12px;
                    padding: 2px 20px;
                    background: rgb(251, 136, 2);
                    border-radius: 5px;
                    color: #fff;
                    box-shadow: 3px 3px 3px rgba(0,0,0,0.5);
                    .pay-amount {
                        color: #a6ff00;
                        font-weight: bold;
                        font-size: 110%;
                    }
                }
    
                .chat-duration-card {
                    // background-image: linear-gradient(#f2eafa, #debcfb);
                    box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
                    cursor: pointer;
                    height: 150px;
                    // max-width: 115px;
        
                    &:hover {
                        border: 1px solid rgb(178, 105, 180);
                    }
                    &.selected {
                        background-image: linear-gradient(#d3a8f9, #8906fb);
                        .duration-info {
                            .chat-time, .money {
                                color: white;
                            }
                            .unit-minute {
                                color: #ddd;
                            }
                        }
                    }
                    .duration-info {
                        .chat-time {
                            font-size: 300%;
                            line-height: 40px;
                            color: rgb(148, 75, 250);
                            font-family: 'fantasy';
                            font-weight: bold;
                        }
                        .unit-minute {
                            text-decoration: underline;
                            text-decoration-skip-ink: auto;
                            padding-bottom: 2px;
                            color: rgb(159, 102, 238);
                        }
                        .money {
                            font-weight: bold;
                            color: rgb(148, 75, 250);
                            font-size: 110%;
                        }
                    }
                }
            }
            .check-out-btn {
                background-color: #fbe953;
                color:#333;
            }
            .required-amount-section {
                border-radius: 5px;
                padding: 5px 10px;
                border: 1px dashed rgb(0, 132, 255);
            }
        }
    }
}

.wait-chat-modal {
    .advisor-avatar {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border: 5px solid greenyellow;
        animation-name: wait-chat-animation;
        animation-duration: 0.8s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-direction: alternate;
    }
    .spent-time {
        font-family: 'Roboto, sans-serif';
        font-size: 25px;
        color: rgb(143, 8, 55)
    }
}

.accept-chat-modal {
    .caller-username {
        font-weight: bold;
    }
    .spent-time {
        font-family: 'Roboto, sans-serif';
        font-size: 25px;
        color: rgb(143, 8, 55)
    }
    .client-avatar {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border: 5px solid greenyellow;
        animation-name: wait-chat-animation;
        animation-duration: 0.8s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-direction: alternate;
    }
    .action-bar {
        img {
            width: 60px;
            cursor: pointer;
        }
    }
}

.wait-chat-modal .coupon,
.accept-chat-modal .coupon {
    top: 5px;
    left: calc(50% - 22px);
    width: 45px !important;
    height: 45px !important;
    color: purple;
}
.edit-note-modal {
    .note-textarea {
        border: none;
        box-shadow: 0 0 3px rgba(0,0,0,0.5);
    }
}

.free-service-modal {
    .buzz-info {
        border-radius: 10px;
        background: #e9ecf9;
        .buzz-message {
            color: #0e3552;
        }
    }
}

.chat-end-modal {
    .review-container{
        padding: 20px 40px;
        @media (max-width: 768px) {
            padding: 20px 20px;
        }
        label {
            color: #7b570a !important;
        }
        .write-reivew-check label,
        .advisor-note-check label {
            color: rgb(160, 25, 183) !important;
            width: 200px;
        }
        
        .value {
            font-weight: bold;
            color:rgb(83, 34, 81);
            padding-left: 50px;
        }
    }
}

@keyframes wait-chat-animation {
    from {
        border-color: greenyellow;
    }
    to {
        border-color: rgb(215, 39, 215);
    }
}

.client-signup-modal {
    .username-rule {
        font-size: 80%;
        color: #555;
    }
}

