.chatoff-message {
    .partner {
        color: purple;
    }
    .messages {
        .one-message {
            border-radius: 5px;
            &.my-message {
                background: #f5f5f5;
            }
            &.partner-message {
                background: #e2e5ff;
            }
        }
    }
    .action {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding: 12px;
    }
    .expired {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
    }
}