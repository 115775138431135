.advisor-preview {
    &.normal {
        background-color: white;
        border: 2px solid #f0eff0;
        border-radius: 10px;
        // box-shadow: #694b4a30 0px 70px 30px -50px;
        position: relative;

        .main-info {
            position: relative;
            .advisor-name {
                font-weight: bold;
                color: rgb(80, 1, 80);
                line-height: 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .avatar-container {
                border-radius: 10px;
                border: 4px solid #ebeb0c;
                transition: all 0.3s;
                overflow: hidden;
                background-image: url("/assets/img/backgrounds/default_user2.png");
                background-size: 100% 100%;
                &:hover {
                    border: 4px solid rgb(163, 237, 163);
                }
                .avatar {
                    width: 100%;
                    padding-top: 100%;
                    cursor: pointer;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    transition: all 0.3s;
                    &:hover {
                        box-shadow: 0px 0px 5px rgba($color: #1b7405, $alpha: 1.0);
                        transform: scale(1.08);
                    }
                }
            }
            .service-name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 20px;
                color: #074db5;
            }
        }

        button.notify-btn {
            background-image: linear-gradient(to bottom, rgb(242,222,109), rgb(247, 204, 37));
            border-radius: 5px;
            padding: 6px 20px;
            color: #111;
        }
    }

    .advisor-action {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;

        .trash-favorite-btn, .add-favorite-btn {
            background-color: #eee;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            &:hover {
                opacity: 0.9;
                margin-top: -1px;
            }
        }
    }

    .coupon {
        top: 5px;
        left: 5px;
    }

    .advisor-comm {
        button.chat-btn {
            text-align: center;
            border-radius: 8px;
            &.online {
                background: #118028;
                color: #f7f785;
            }
            &.offline{
                background: white;
                border: 2px solid #a08aed;
                color: #555;
                .chat-icon {
                    border-radius: 5px;
                    fill: #a08aed;
                }
            }
            &.busy {
                background: #ffc1bf;
            }
        }
    
        &.small-version {
            button.chat-btn {
                // width: 60px !important;
                border-radius: 5px;
            }
        }
    }
}

.advisor2-preview {
    &.online {
        img {
            border: 3px solid rgb(43, 186, 43);
        }
    }
    &.offline {
        img {
            border: 3px solid #ddd;
        }
    }
    &.busy {
        img {
            border: 3px solid rgb(236, 97, 97);
        }
    }
}

.advisor-card {
    // width: 280px;
    // height: 400px;
    // background-color: white;
    border-radius: 32px;
    padding: 3px;
    position: relative;
    box-shadow: #694b4a30 0px 70px 30px -50px;
    transition: all 0.5s ease-in-out;
}
.featured-advisor.card.advisor-card {
    width: 100%;
    // height: 470px;
    // background-color: #fff7d6;
    border-radius: 8px;
    padding: 3px;
    position: relative;
    box-shadow: #694b4a30 0px 70px 30px -50px;
    transition: all 0.5s ease-in-out;
    // @media (max-width:576px) {
    //     // width: 200px;
    //     height: 390px;
    // }

    // @media (max-width:450px) {
    //     // width: 200px;
    //     height: 300px;
    // }
}
.card .mail {
    position: absolute;
    right: 2rem;
    top: 1.4rem;
    background: transparent;
    border: none;
    z-index: 2;
}

.card .mail i {
    color: #c54d47;
    font-size: 25px;
    transition: .3x ease-in-out;
    cursor: pointer;
}

.card .mail  i:hover {
    color: #f55d56;
}

.card .profile-pic {
    // position: absolute;
    width: 100%;
    // height: calc(100% - 6px);
    top: 3px;
    left: 3px;
    border-radius: 10px;
    z-index: 1;
    border: 4px solid rgb(235, 235, 12);
    overflow: hidden;
    transition: all 0.3s;
    background-image: url("/assets/img/backgrounds/default_user2.png");
    background-size: 100% 100%;
    cursor: pointer;

    &:hover {
        border: 4px solid rgb(237, 163, 237);
    }
}

.card .profile-pic img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    -o-object-position: 0px 0px;
    object-position: 0px 0px;
    transition: all 0.3s;
    &:hover {
        box-shadow: 0px 0px 5px rgba($color: #1b7405, $alpha: 1.0);
        transform: scale(1.08);
    }
}

// .card .profile-pic img {
//     width: 100%;
//     // height: 100%;
//     -o-object-fit: contain;
//     object-fit: contain;
//     -o-object-position: 0px 0px;
//     object-position: 0px 0px;
//     transform-origin: 45%  20%;
//     transition: all 0.5s ease-in-out 0s;
// }

.card .bottom {
    position: relative;
    bottom: 3px;
    left: 3px;
    right: 3px;
    // background: #faf8f1;
    top: 50%;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 29px;
    border-bottom-right-radius: 29px;
    z-index: 2;
    // box-shadow: rgba(96, 75, 74, 0.1882352941) 0px 5px 5px 0px inset;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.card .bottom  {
    font-size: 1.1rem;
    .content{
        // position: absolute;
        // bottom: 0;
        padding: 8px;
        color: #575f5c;
        top: 30px;
        left: 1.5rem;
        right: 1.5rem;
        height: 180px;    
        border-radius: 29px;
    }
    .about-me {
        font-size: 1rem;
    }
    @media (max-width:576px) {
        // width: 200px;
        font-size: 1rem;
        .about-me {
            font-size: 0.9rem;
        }
    }

    @media (max-width:450px) {
        // width: 200px;
        font-size: 0.85rem;
        height: 150px;    
        .about-me {
            font-size: 0.75rem;
        }
    }
}

.card .bottom .content .service {
    color: rgb(18, 1, 0);
    font-weight: 500;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.card .bottom .content .name {
    display: block;
    color: #500150;
    font-weight: bold;
}

.card .bottom .content .about-me {
    display: block;
    color: rgb(18, 1, 0);
    margin-top: 0.25rem;
    visibility: visible;
    opacity: 1;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    transition: visibility 600ms linear 600ms, opacity 300ms ease-out, height 0s linear 300ms;
}

.card .bottom .bottom-bottom {
    // width: 100%;
    position: absolute;
    bottom: 0.25rem;
    padding: 6px;
    // background: #faf8f1;
    left: 0.5rem;
    right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    @media (max-width:576px) {
        // width: 200px;
        font-size: 1rem;
    }

    @media (max-width:450px) {
        // width: 200px;
        font-size: 0.75rem;
    }
}

.social-links-container {
    display: flex;
    gap: 1rem;
}

.card .bottom .bottom-bottom
.social-links-container i {
    height: 20px;
    color: white;
    filter: drop-shadow(0 5px 5px rgba(165, 132, 130, 0.1333333333));
    cursor: pointer;
}


.card .bottom .bottom-bottom .button {
    background: white;
    color: #fff7d9;
    border: none;
    border-radius: 20px;
    padding: 0.4rem 0.6rem;
    box-shadow: rgba(165, 132, 130, 0.1333333333) 0px 5px 5px 0px;
    cursor: pointer;
}

.card .bottom .bottom-bottom .button:hover {
    // background: #fff7d9;
    background-image: linear-gradient();
    color: white;
}

// .card {
//     border-top-left-radius: 55px;
// }

// .card:hover .bottom .content {
//     top: 50px;
//     transition: 600ms;
// }

// .card:hover .bottom .content .about-me {
//     visibility: visible;
//     opacity: 1;
//     line-clamp: 6;
//     -webkit-line-clamp: 6;
//     transition-delay: 600ms;
// }

// .card:hover .bottom {
//     top: 20%;
//     border-radius: 80px 29px 29px 29px;
//     transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
// }

// .card:hover .profile-pic {
//     height: 100px;
//     width: 100px;
//     aspect-ratio: 1;
//     top: 10px;
//     left: 10px;
//     border-radius: 50px;
//     z-index: 3;
//     border: 5px solid #ffe897;
//     box-shadow: rgba(96, 75, 74, 0.1882352941) 0px 5px 5px 0px;
//     transition: all 0.5s ease-in-out, z-index 0.5s ease-in-out 0.1s;
// }

// .card:hover .profile-pic:hover {
//     transform: scale(1.2);
//     border-radius: 0px;
// }

// .card:hover .profile-pic img {
//     position: relative;
//     top: -30px;
//     transform: scale(1.4);
//     -o-object-position: 0px 25px;
//     object-position: 0px 25px;
//     transition: all 0.5s ease-in-out 0.5s;
// }

// .card .bottom .online {
//     background: linear-gradient(#daeb8a , #9ed36d);
// }

// .card .bottom .busy {
//     background: linear-gradient(#dab9b5 , #91595e);
// }

// .card .bottom .away {
//     background: #dce0b2;
// }
.card .bottom .status {
    padding: 2px 4px;
    color: rgb(18, 1, 0);
    border-radius: 8px;
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 0.85rem;
}
.card .bottom button.away {
    background: linear-gradient(#c8a8fd , #7d67f0);
    color: #ffffff;
}
