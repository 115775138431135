.advisor-show-container {
    .chat-btn {
        border-radius: 5px;
        text-align: center;
        width: 150px;
        padding: 8px;

        &.busy {
            background-color: rgb(221, 80, 80);
            color: #eee;
        }
        &.online {
            background-color: #118028;
            color: #eee;
            cursor: pointer;
        }
        &.offline {
            background-color: #cbb4ff;
            color: #333;
        }
    }
    
    .main-info-container {
        .photo {
            width: 200px;
            height: 200px;
            background-image: url("/assets/img/backgrounds/default_user2.png");
            background-size: 100% 100%;
            @media (max-width: 540px) {
                padding: 0px !important;
            }    
            img {
                height: 100%;
                width: 100%;
                border-radius: 50%;
                border: 3px solid #ebeb0c;
            }
        }
        .advisor-name {
            font-size: 120%;
            color: purple;
            line-height: 25px;
        }
        .rate-chat {
            font-weight: bold;
            font-size: 120%;
        }
        .action-bar {
            position: absolute;
            top: 10px;
            right: 15px;
            .trash-favorite-btn, .add-favorite-btn {
                background-color: #eee;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                &:hover {
                    opacity: 0.9;
                }
            }
            .buzz-btn {
                .bell-icon {
                    cursor: pointer;
                    &:hover {
                        scale: 1.1;
                    }
                }
            }
        }
        &.pc {
            .photo {
                img {
                    float: left;
                }
            }
        }
    }

    .about-me, .offer-services, .categories {
        h5 {
            color: #AD3E00;
        }
        i.fas {
            color: #AD3E00;
        }
    }
    .categories .category-tags {
        display: flex;
        flex-wrap: wrap;
        .category-tag {
            padding: 3px 15px !important;
            border-radius: 5px;
            border: 1px solid gray;
            @media (max-width: 576px) {
                width: 100%;
            }
        }
    }

    
    .advisor-reviews {
        position: relative;
        .reviews-tab {
            .good_review, .bad_review {
                width: 30px;
            }
            .nav-link {
                @media (max-width:576px) {
                    padding: 10px 15px;
                }
            }
        }
        .review {
            border-radius: 10px;
            &.happy {
                background: rgb(220, 250, 220);
            }
            &.unhappy {
                background: rgb(250, 220, 220);
            }
            .icon {
                width:25px;
            }
            .client {
                font-weight: bold;
            }
            p {
                word-wrap: break-word;
            }
        }
    }
}

