
.client-mypage {
    .share-with-advisor-public-info-card {
        img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            border: 1px solid gray;
            &:hover {
                border: 2px solid green;
            }
        }
        label {
            margin-bottom: 5px;
        }
        .edit-avatar {
            position: absolute;
            left: calc(50% - 8px);
            bottom: -5px;
        }
    }
}

