.client-advisor-detail {
    .chats-history-table {
        .chat-note {
            color: #888;
            background: #fae8c8;
            border-radius: 5px;
        }
        .chat-duration {
            border-radius: 5px;
            border: 1px solid #d3d3d3;
            padding: 1px 10px;
            font-size: 90%;
        }
        .chat-amount {
            border-radius: 5px;
            border: 1px solid #ddd;
            color: #888;
            min-width: 80px;
            text-align: center;
        }
    }
}