.ad_auth_login {
    padding: 150px 0px;
    @media (max-width: 768px) {
        padding: 100px 0px;
    }
    .login-form {
        padding:50px 20px;
        width: 400px;
        max-width: 90%;
        border-radius: 10px;
        box-shadow: 0 0 12px rgba(0,0,0,0.12);

        @media (max-width: 768px) {
            padding: 20px;
        }
    }
}

.ad-auth-signup {
    position: relative;
    margin-top: 80px;
    margin-bottom: 100px;

    @media (max-width: 992px) {
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .unlock-opportunity {
        position: relative;
        &::before {
            content: '';
            background-image: url('../../../assets/img/backgrounds/flower.png');
            background-repeat: no-repeat;
            opacity: 0.1;
            z-index: -1;
            position: absolute;
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
        }

        h2 {
            color:purple !important;
        }
        .check-img img {
            width: 20px;
            margin-right: 20px;
        }
        ul {
            padding: 0px 15px;
        }
    }

    .signup-form {
        padding:50px 20px;
        max-width: 500px;
        width: 90%;
        border-radius: 10px;
        box-shadow: 0 0 12px rgba(0,0,0,0.12);

        @media (max-width: 768px) {
            padding: 20px;
        }
    }
}

.ad-auth-signup-step {
    position: relative;
    .card-header {
        background-color: #ebcbef;
        color: purple;
    }
    .label {
        font-weight: bold;
        font-size:14px;
        color: #111;
    }

    .signup-step1-card {
        .sub-title {
            font-weight: bold;
        }
    }

    .signup-step2-card {
        width: 100%;
        .personal-form {
            width: 500px;
        }
    }

    .signup-step3-card {
        width: 100%;
        .card-body {
            @media (max-width: 768px) {
                flex-direction: column;
                align-items: center;
            }
            .main-info {
                box-shadow: 0px 0px 6px rgba(0,0,0,0.12);
                padding: 20px 30px;
                @media (max-width:576px) {
                    padding: 15px;
                }
                .avatar {
                    @media (max-width: 576px) {
                        margin: 0px !important;
                        img {
                            width: 150px !important;
                            height: 150px !important;
                        }
                    }
                    img {
                        width: 180px;
                        height: 180px;
                        border-radius: 50%;
                        border: 5px solid rgb(235, 235, 12);
                        transition: all 0.3s;
                        &:hover {
                            scale: 1.02;
                            border-color: pink;
                        }
                    }
                }
                .stage-service-name {
                    @media (max-width: 576px) {
                        align-items: center;
                        width:100% !important;
                    }
                    flex: 1;
                    max-width: 500px;
                    display: flex;
                    flex-direction: column;
                }                
            }
        }
    }

    .signup-step4-card {
        .video-guide {
            border-radius: 5px;
            background: #f5dbab;
            padding: 10px 20px 20px 10px;
        }
    }

    .upload-progress {
        position: absolute;
        left: 0;
        top: 0;
        left: 0;
        right: 0;
        background: #eee;
        opacity: 0.8;
    }
}

.advisor-signup-confirm {
    padding: 50px 0px;
}

.advisor-signup-thanks {
    padding: 50px 0px;
    .thank-you {
        font-size: 300%;
        color:purple;
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .message {
        font-size: 120%;
        border-radius: 10px;
        border: 2px solid green;
        background: green;
        padding: 5px;
        div {
            border-radius: 8px;
            border: 2px solid white;
            padding: 10px;
            color: white;
        }
    }
}

.advisor-under-review {
    padding: 50px 0px;
    .message {
        border-radius: 15px;
        background: rgb(11, 167, 11);
        padding: 5px;
        color: white;
        margin-bottom: 40px;
        div {
            border: 2px white solid;
            border-radius: 10px;
            padding: 20px;
            font-size: 150%;
            text-align: center;
        }
    }
}

.advisor-upload-applicant-card {
    margin: 50px 0px;
    width: 100%;
    .label-1 {
        color: rgb(58, 58, 58);
        font-weight: bold;
        font-size: larger;
        text-align: center;
        margin-bottom: 20px;
    }
    .verify-photos {
        img {
            width: 300px;
            height: 200px;
            border-radius: 5px;
        }
        .id-card {
            box-shadow: 0px 0px 6px rgba($color: #000000, $alpha: 0.12);
        }
        .cert-files {
            box-shadow: 0px 0px 6px rgba($color: #000000, $alpha: 0.12);
            padding-bottom: 20px;
            .cert-file {
                .img-container {
                    position: relative;
                    &:hover {
                        .cert-delete {
                            display: block;
                        }
                    }
                    .cert-delete {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        display: none;
                    }
                }
            }
        }
        .tax-file {
            box-shadow: 0px 0px 6px rgba($color: #000000, $alpha: 0.12);
            padding-bottom: 20px;
            img {
                width: 200px;
                height: 250px;
            }
        }
    }
}