.clients-table {
    .client-avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    .online-status {
        padding: 3px 5px;
        border-radius: 5px;
        text-align: center;
        width: 80px;
        &.online {
            background-color: green;
            color: white;
        }
        &.offline {
            background-color: #ddd;
            color: #aaa;
        }
    }
    .user-status {
        padding: 3px 5px;
        border-radius: 5px;
        text-align: center;
        width: 120px;
        &.approved {
            background-color: green;
            color: white;
        }
        &.suspended {
            background-color: red;
            color: #333;
        }
    }
}