.chatoff-threads {
    .one-thread {
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 5px;
        position: relative;
        cursor: pointer;
        &:hover {
            background: #eee;
        }
        &.closed {
            opacity: 0.3;
        }
        .partner-name {
            color: #555;
            font-weight: bold;
        }
        .last-message {
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
        }
        .unread-msg-count {
            border-radius: 50%;
            color: white;
            background-color: #d301d3;
            width: 20px;
            height: 20px;
            font-size: 80%;
            position: absolute;
            right:-10px;
            top: 6px;
        }
    }
}