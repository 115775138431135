.admin-reviews-table {
    .review-type {
        &.happy {
            color: green;
        }
        &.unhappy {
            color: red;
        }
    }
    .review-content {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
}

.admin-edit-review {
    box-shadow: 0px 0px 6px rgba(0,0,0,.2);
    border-radius: 10px;
    label {
        width: 50px;
    }
}
