.admin-container1 {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .topbar-container {
        background: #024089;
        height: 70px;
    }
    
    .sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        background: #eef5fa;
        z-index: 9999;
        transition: transform 0.3s;
        .sidebar-logo {
            font-family: 'QuincyCF';
            font-size: 150%;
            height: 70px;
            border-bottom: 1px solid #92c3ee;
            cursor: pointer;
            img {
                width: 40px;
                margin-right: 10px;
            }
        }
    }

    &.sidebar-expanded .sidebar {
        transform: translateX(0);
    }
    &.sidebar-collapsed .sidebar {
        transform: translateX(-220px);
    }
}

