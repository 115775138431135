.client-my-wallet-container {
    .balance-card {
        .balance-and-charge {
            @media (max-width: 768px) {
                flex-direction: column;
            }
            .my-balance {
                .available-balanace {
                    font-weight: bold;
                    color: purple;
                }
            }
            .charge-balance {
                @media (max-width: 768px) {
                    margin-top: 10px;
                }
            }
        }
    }
    .transactions-card {
        label {
            color: #777;
        }
        table {
            min-width: 300px;
            tbody {
                tr {
                    .success {
                        color: #069d06
                    }
                    .pay-in {
                        color: #069d06;
                        &::before{
                            content: '+';
                        }
                    }
                    .withdraw {
                        color: black;
                        &::before{
                            content: '-';
                        }
                    }
                }
            }
        }
    }
}