.advisor-all-buzzes {
    .title {
        font-size: 120%;
        font-weight: bold;
        padding-left: 15px;
        color: #333;
    }
    .buzz-list {
        overflow: auto;
        table.confideas-table {
            tr {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.advisor-all-coupons {
    .title {
        font-size: 120%;
        font-weight: bold;
        padding-left: 15px;
        color: #333;
    }
    .coupon-list {
        overflow-x: auto;
        .message-column {
            .free-minutes-coupon {
                &.consumed, &.expired {
                    color: #888;
                    svg {
                        opacity: 0.2;
                    }
                }
            }
            .no-free-minutes-coupon {
                svg {
                    opacity: 0.2;
                }
            }
        }
    }
}