.advisor-dashboard-container {
    position: relative;

    .advisor-alarms-container {
        .alarm {
            background-color: rgb(255, 241, 241);
            border-radius: 5px;
            border: 1px dashed #0077ff;
            padding: 10px 20px;
            &.warning {
                border-color: red;
            }
        }

    }

    .my-pilot {
        .my-status-sm {
            padding-bottom: 20px;
            border-bottom: 1px solid #eee;
        }
    }

    .verify-items {
        border-radius: 5px;
        border: 1px dashed rgb(253, 136, 155);
        background-color: rgb(252, 238, 240);
        padding: 10px;
    }
    
    .my-clients {
        .search-input {
            width: 300px;
            @media (max-width: 576px) {
                width: 100%;
            }    
        }
    
        .client-list {
            @media (max-width: 768px) {
                overflow: scroll;
            }
            table {
                min-height: 50px;
                tr .client-info {
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .fixed-pilot {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 50px;
        background: #f5faff;
        border-bottom: 1px solid #33a0d5;
        transition: all 0.5s;
        z-index: 50;
    }    
}


