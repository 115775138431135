.advisor-client-detail {
    .client-key-note-card
    {
        .client-main-info {
            position: relative;
            padding: 30px 10px 10px 30px;
            .action {
                position: absolute;
                top: -5px;
                right: 5px;
            }
        }
        .key-note-section {
            @media (max-width: 576px) {
                margin: 10px 0px 0 0 !important;
            }
        }
        .key-note {
            background-color: rgb(250, 232, 200);
            padding: 10px;
            border-radius: 5px;
            height: 100%;
        }
    }
    .chats-history-table {
        .chat-note {
            color: #888;
            background: #fae8c8;
            border-radius: 5px;
        }
        .chat-duration {
            border-radius: 5px;
            border: 1px solid #d3d3d3;
            padding: 1px 10px;
            font-size: 90%;;
        }
        .chat-earning {
            border-radius: 5px;
            border: 1px solid #d9ba10;
            color: #83710f;
            font-weight: bold;
            width: 80px;
            text-align: center;
        }
    }
}
