.advisor-comm {
    button.chat-btn {
        text-align: center;
        border-radius: 5px;
        &.online {
            background: #118028;
            color: #f7f785;
        }
        &.offline{
            background: #cbb4ff;
            color: #555;
        }
        &.busy {
            background: #ffc1bf;
        }
    }

    &.small-version {
        button.chat-btn {
            // width: 60px !important;
            border-radius: 5px;
        }
    }
}

