.admin-trackword-table {
    .status {
        padding: 5px 10px;
        width: 100px;
        border-radius: 5px;
        text-align: center;
        &.using {
            background: #24b324;
            color: white;
        }
        &.unusing {
            background: #aaa;
            color: #eee;
        }
    }
}