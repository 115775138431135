.chat-detail-container {
    .chat-maininfo {
        box-shadow: 0px 1px 3px rgba(0,0,0,.2);
        border-radius: 10px;
        .partner-avatar {
            border-radius: 50%;
            width: 100px;
            height: 100px;
            border: 3px solid gold;
            @media (max-width: 768px) {
            }
        }
        .chat-other-info {
            .earning {
                border-radius: 5px;
                border: 2px solid gold;
            }
            .duration {
                border-radius: 5px;
                border: 2px solid #ddd;
            }
        }
    }
}