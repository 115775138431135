.article-container {
    // background-color: purple;
    .article {
        background-color: white;
        font-size: 18px !important;
        .article_title {
            text-align: center;
            color: purple;
        }
        .article_short_desc {
            background-color: #63aec9;
            color: white;
            border-radius: 5px;
        }
        img.article_image {
            border-radius: 5px;
            width: 500px;
            height: auto;
        }
    }

    @media (max-width: 576px) {
        img.article_image {
            max-width: 90% !important;
        }
    }
}