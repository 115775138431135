.info-navbar {
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 15px;
    padding-top: 5px;
    background: #dfedff;
    a {
        color: #333;
        &.active {
            color: purple;
        }
    }
}