.advisor-edit-container, .advisor-edit-listing, .advisor-activate-listing {
    padding: 40px 10px;
    .listing-status, .profile-status {
        padding: 5px 10px;
        border-radius: 5px;
        width: 100px;
        text-align: center;
    }
    .listing-status.show, .profile-status.approved {
        background: #09a509;
        color: white
    }
    .listing-status.hide, .profile-status.declined {
        background: rgb(242, 69, 69);
        color: white;        
    }
    .profile-status.waiting {
        background: rgb(248, 229, 155);
    }

    .accordion-header .accordion-button {
        background-color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        @media (max-width: 576px) {
            .header {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
    .label {
        font-weight: bold;
        font-size: 14px;
    }
    .accordion-item-title {
        padding: 10px;
    }
    
    .main-info {
        box-shadow: 0px 0px 6px rgba(0,0,0,0.12);
        padding: 20px 30px;
        @media (max-width:576px) {
            padding: 15px;
        }
        .avatar {
            @media (max-width: 576px) {
                margin: 0px !important;
                img {
                    width: 150px !important;
                    height: 150px !important;
                }
            }
            img {
                width: 180px;
                height: 180px;
                border-radius: 50%;
                border: 5px solid rgb(235, 235, 12);
                transition: all 0.3s;
                &:hover {
                    scale: 1.02;
                    border-color: pink;
                }
            }
        }
        .stage-service-name {
            @media (max-width: 576px) {
                align-items: center;
                width:100% !important;
            }
            flex: 1;
            max-width: 500px;
            display: flex;
            flex-direction: column;
        }
    }

    .application-document-form {
        .label-1 {
            color: rgb(58, 58, 58);
            font-weight: bold;
            font-size: larger;
            text-align: center;
            margin-bottom: 20px;
        }
        img {
            width: 250px;
            height: 150px;
            max-width: 100%;
            // border: 1px solid green;
            border-radius: 10px;
        }
        .id-card {
            box-shadow: 0px 0px 6px rgba($color: #000000, $alpha: 0.3);
            background-color: rgb(240, 242, 249);
            padding-bottom: 20px;
        }
        .cert-files {
            box-shadow: 0px 0px 6px rgba($color: #000000, $alpha: 0.3);
            background-color: rgb(249, 240, 249);
            padding-bottom: 20px;
            .cert-file {
                .img-container {
                    position: relative;
                    .cert-delete {
                        position: absolute;
                        top: 5px;
                        right: 10px;
                        &:hover{
                            opacity: 0.9;
                            margin-top: -1px;
                        }
                    }
                }
            }
        }
        .tax-file {
            box-shadow: 0px 0px 6px rgba($color: #000000, $alpha: 0.3);
            background-color: rgb(240, 249, 240);
            padding-bottom: 20px;
            img {
                width: 150px;
                height: 200px;
            }
        }
        .submitted-icon {
            position: absolute;
            left: 2px;
            right: 2px;
            top: calc(50% - 20px);
            background-color: #e6ff00;
            padding: 5px 20px;
            color: #000;
            opacity: 0.9;
        }
    }

    .advisor-payment-method-edit-card {
        .payment-method {
            border-radius: 5px;
            box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
            .remove {
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}
